<template>
  <div class="user-box ml-2">
    <a href="javascript:void(0);" class="p-0 d-flex align-items-center" id="userBoxPopover">
      <div class="d-block p-0 avatar-icon-wrapper">
        <!-- <span class="badge badge-circle badge-success p-top-a">Online</span> -->
        <div class="avatar-icon rounded avatar-icon-sm">
          <img src="@/assets/img/avatars/avatar4.jpg" alt />
        </div>
      </div>  
      <!-- <div class="d-none d-md-block pl-2">
        <div class="font-weight-bold">George Martin</div>
        <span class="text-black-50">admin</span>
      </div> -->
      <!-- <span class="pl-3">
        <font-awesome-icon icon="angle-down" class="opacity-5" />
      </span> -->
    </a>
    <b-popover
      triggers="click blur"
      placement="bottom"
      @show="showPopoverIntegrations"
      ref="userBoxPopover"
      target="userBoxPopover"
    >
      <ul class="list-group list-group-flush text-left bg-transparent">
        <li class="list-group-item rounded-top">
          <ul class="nav nav-pills nav-pills-hover flex-column">
            <li class="nav-item">
              <router-link to="/my-profile" class="nav-link" tag="a">My Account</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/current-plan" class="nav-link" tag="a">Subscription</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0);">Logout</a>
            </li>
            <li class="nav-item mobile-view">
            <router-link to="/backup" class="btn mr-1">
              <font-awesome-icon icon="cloud-upload-alt" class="mr-1" />Backup
            </router-link>
            </li>
            <li class="nav-item mobile-view">
            <router-link to="/dns-manager" class="btn mr-1">
              <font-awesome-icon icon="tasks" class="mr-1" />DNS
            </router-link>
            <li class="nav-item mobile-view">
            <router-link to="/team" class="btn mr-1">
              <font-awesome-icon icon="users" class="mr-1" />Team
            </router-link>
            </li>
            <li class="nav-item mobile-view">
            <router-link to="/ticket-list" class="btn mr-1">
              <font-awesome-icon icon="users-cog" class="mr-1" />Support
            </router-link>
            </li>
               <!-- <li class="nav-item mobile-view">
            <router-link to="/email-accounts" class="btn mr-1">
              <font-awesome-icon icon="envelope" class="mr-1" />Mail
            </router-link>
            </li> -->
          </ul>
        </li>
      </ul>
    </b-popover>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlusCircle,
  faChartBar,
  faAngleDown,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faDribbble,
  faFacebook,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";
library.add(
  faDribbble,
  faPlusCircle,
  faChartBar,
  faAngleDown,
  faFacebook,
  faTwitter,
  faEnvelope
);
export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon
  },
  methods: {
    showTooltip() {
      this.$refs.tooltipSuccess._toolpop
        .getTipElement()
        .classList.add("tooltip-success");
    },
    showPopoverIntegrations() {
      this.$refs.userBoxPopover._toolpop
        .getTipElement()
        .classList.add(
          "popover-custom-wrapper",
          "popover-custom-lg",
          "popover-secondary"
        );
    }
  }
};
</script>
<style lang="scss" scoped>
.mobile-view{
    display: none;
}
@media only screen and (max-width: 991px) {
  .mobile-view{
    display: block!important;
  }
}
</style>