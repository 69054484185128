<template>
  <div class="app-wrapper">
    <div class="app-main">
      <div class="admin-app-header d-flex justify-content-between ">
        <div>
          <router-link tag="a" class="d-block float-left float-left" active-class="active" to="/admin/dashboard" exact>
            <img class="logo-normal" src="@/assets/img/admin-logo-inverse.png" alt />
          </router-link>
          <div class="float-left mt-lg-1 mt-md-4 mt-sm-4 mt-xs-4 ml-2 mb-3">
            | Welcome to Admin Area!
          </div>
        </div>
        <div class="row">
          <router-link to="/admin/users"  class="btn mr-1">
            <font-awesome-icon icon="users" class="mr-1" />Users
          </router-link>
          <router-link to="/admin/servers" class="btn mr-1">
            <font-awesome-icon icon="server" class="mr-1" />Servers
          </router-link>
          <router-link to="/admin/packages" class="btn mr-1">
            <font-awesome-icon icon="cube" class="mr-1" />Packages
          </router-link>
           <router-link to="/admin/coupons" class="btn mr-1">
            <font-awesome-icon icon="tags" class="mr-1" />Coupons
          </router-link>
           <router-link to="#" class="btn mr-1">
            <font-awesome-icon icon="file" class="mr-1" />Reports
          </router-link>
          <router-link to="/admin/api" class="btn mr-1">
            <font-awesome-icon icon="key" class="mr-1" />API
          </router-link>
      <div class="row">
            <router-link  to="/admin/php-management" class="btn mr-1 ml-3">
            <font-awesome-icon icon="key" class="mr-1" />Easy PHP
          </router-link>
           <router-link  to="/admin/backup-admin" class="btn pl-0">
            <font-awesome-icon icon="key" class="mr-1" />Backup
          </router-link>
           <router-link  to="/admin/referrals" class="btn pl-0 ml-4">
            <font-awesome-icon icon="gift" class="mr-1" />Referrals
          </router-link>
           <router-link  to="/admin/911" class="btn pl-0 ml-4">
            <font-awesome-icon icon="shield-alt" class="mr-1" />911
          </router-link>
           <router-link  to="/admin/vultrplans" class="btn pl-0 ml-4">
            <font-awesome-icon icon="" class="mr-1" />Vultr
          </router-link>
</div>
      </div>
        <div class="user-box ml-2">
          <a href="javascript:void(0);" class="p-0 d-flex align-items-center" id="userBoxPopover">
            <div class="d-block p-0 avatar-icon-wrapper">
              <span class="badge badge-circle badge-success p-top-a">Online</span>
              <div class="avatar-icon rounded">
                <img src="@/assets/img/avatars/avatar4.jpg" alt />
              </div>
            </div>
            <div class="d-none d-md-block pl-2">
              <div class="font-weight-bold">George Martin</div>
              <span class="text-black-50">admin</span>
            </div>
            <span class="pl-3">
              <font-awesome-icon icon="angle-down" class="opacity-5" />
            </span>
          </a>
          <b-popover
            triggers="click blur"
            placement="bottom"
            @show="showPopoverIntegrations"
            ref="userBoxPopover"
            target="userBoxPopover"
          >
            <ul class="list-group list-group-flush text-left bg-transparent">
              <li class="list-group-item rounded-top">
                <ul class="nav nav-pills nav-pills-hover flex-column">
                  <li class="nav-item">
                    <router-link to="/my-profile" class="nav-link" tag="a">My Account</router-link>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);">Logout</a>
                  </li>
                </ul>
              </li>
            </ul>
          </b-popover>
        </div>
      </div>
      <div class="admin-app-content bg-white">
        <div class="app-content--inner">
          <slot></slot>
        </div>
        <div class="app-footer font-size-sm text-black-50">
          <div>
            © 2020 - CloudStick LLC - created by
            <a
              href="https://cloudstick.io"
              title="UiFort.com"
              target="_blank"
            >CloudStick.io</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown, faCube, faPuzzlePiece,faFile,faSync,faUsers,faServer, faKey, faTags, faGift, faShieldAlt} from "@fortawesome/free-solid-svg-icons";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faAngleDown);
library.add(faAngleUp);
library.add(faTimes);
library.add(faCube);
library.add(faPuzzlePiece);
library.add(faPuzzlePiece);
library.add(faPuzzlePiece);
library.add(faFile);
library.add(faSync);
library.add(faUsers);
library.add(faServer);
library.add(faKey);
library.add(faTags);
library.add(faGift);
library.add(faShieldAlt);




export default {
  name: "admin",
  components: {
    "font-awesome-icon": FontAwesomeIcon
  },
  methods: {
    showTooltip() {
      this.$refs.tooltipSuccess._toolpop
        .getTipElement()
        .classList.add("tooltip-success");
    },
    showPopoverIntegrations() {
      this.$refs.userBoxPopover._toolpop
        .getTipElement()
        .classList.add(
          "popover-custom-wrapper",
          "popover-custom-lg",
          "popover-secondary"
        );
    },
  }
};
</script>