import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import BootstrapVue from "bootstrap-vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
import CKEditor from 'ckeditor4-vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);
import VueOtp2 from 'vue-otp-2';

Vue.use(VueOtp2);
import OtpInput from "@bachdgvn/vue-otp-input";

Vue.component("v-otp-input", OtpInput);
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

Vue.use( CKEditor );


Vue.use(BootstrapVue);

Vue.config.productionTip = true;

// import Default from "./Layout/Wrappers/baseLayout.vue";
import Default from "./Layout/Wrappers/profilePagesLayout.vue";

import Layouts from "./Layout/Wrappers/layoutsExamples.vue";
import Pages from "./Layout/Wrappers/pagesLayout.vue";
import SecondaryPage from "./Layout/Wrappers/secondaryPageLayout.vue";
import SecondaryPageAdmin from "./Layout/Wrappers/secondaryPageLayout-admin.vue";
import WebAppPage from "./Layout/Wrappers/WebAppPageLayout.vue";
import WebAppPageAdmin from "./Layout/Wrappers/WebAppPageLayout-admin.vue";
import Apps from "./Layout/Wrappers/appLayout.vue";
import Apps2 from "./Layout/Wrappers/app2Layout.vue";
import Appssm from "./Layout/Wrappers/appsmLayout.vue";
import ErrorPage from "./Layout/Wrappers/ErrorPageLayout.vue";



import profilePagesLayout from "./Layout/Wrappers/profilePagesLayout.vue";
import EmailPagesLayout from "./Layout/Wrappers/EmailPagesLayout.vue";
import EmailsPagesLayout from "./Layout/Wrappers/EmailsPagesLayout.vue";
import profilePagesLayoutAdmin from "./Layout/Wrappers/profilePagesLayout-admin.vue";
import adminLayout from "./Layout/Wrappers/adminLayout.vue";
import { ToggleButton } from 'vue-js-toggle-button'
import CountryFlag from 'vue-country-flag'

Vue.component('ToggleButton', ToggleButton);
Vue.component('country-flag', CountryFlag)
Vue.component("default-layout", Default);
Vue.component("examples-layout", Layouts);
Vue.component("pages-layout", Pages);
Vue.component("secondary-page-layout", SecondaryPage);
Vue.component("secondary-page-admin-layout", SecondaryPageAdmin);
Vue.component("web-page-layout", WebAppPage);
Vue.component("web-page-admin-layout", WebAppPageAdmin);
Vue.component("apps-layout", Apps);
Vue.component("apps2-layout", Apps2);
Vue.component("appssm-layout", Appssm);
Vue.component("error-page-layout", ErrorPage);


Vue.component("profile-page-layout", profilePagesLayout);
Vue.component("Email-page-layout", EmailPagesLayout);
Vue.component("Emails-page-layout", EmailsPagesLayout);
Vue.component("profile-page-admin-layout", profilePagesLayoutAdmin);
Vue.component("admin-layout", adminLayout);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
