<template>
  <div class="app-sidebar app-sidebar--dark" :class="sidebarbg">
    <div class="app-sidebar--header">
      <div class="nav-logo w-100 text-center">
        <router-link tag="a" class="d-block" active-class="active" to="/dashboard" exact>
                  <img v-if="!sidebarCollapsed" class="logo-normal pl-1 pr-1 w-85" src="@/assets/img/logo-main-1.png" alt />
          <img v-else class="logo-small" src="@/assets/img/admin-logo-small.png" alt />
        </router-link>
      </div>
      <button
        class="toggle-sidebar rounded-circle btn btn-sm bg-white shadow-sm-dark text-primary"
        v-b-tooltip.right
        title="Expand Sidebar"
        v-bind:class="{'is-active': sidebarCollapsed}"
        type="button"
        v-on:click="toggleSidebar"
      >
        <font-awesome-icon icon="arrows-alt-h" />
      </button>
    </div>
    <VuePerfectScrollbar class="app-sidebar--content">
      <div class="sidebar-navigation">
        <sidebar-menu showOneChild :menu="menu" />
      </div>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import Vue from "vue";
Vue.component("user-icon", UserIcon);
Vue.component("lock-icon", LockIcon);
Vue.component("dollar-sign-icon", DollarSignIcon);
Vue.component("user-plus-icon", UserPlusIcon);
Vue.component("login-icon", LogInIcon);
Vue.component("trash-icon", TrashIcon);
Vue.component("mail-icon", MailIcon);
Vue.component("package-icon", PackageIcon);
Vue.component("home-icon", HomeIcon);
Vue.component("ticket-icon", PlusSquareIcon);
Vue.component("ticket-list-icon", ListIcon);
Vue.component("ticket-response-icon", ArrowRightIcon);
Vue.component("key-icon", KeyIcon);

import { SidebarMenu } from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  UserIcon,
  LockIcon,
  DollarSignIcon,
  UserPlusIcon,
  LogInIcon,
  TrashIcon,
  MailIcon,
  PackageIcon,
  HomeIcon,
  ListIcon,
  PlusSquareIcon,
  ArrowRightIcon,
  KeyIcon,
} from "vue-feather-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    SidebarMenu,
    VuePerfectScrollbar,
    "font-awesome-icon": FontAwesomeIcon
  },
  props: {
    sidebarbg: String
  },
  data() {
    return {
      menu: [
        {
          header: true,
          title: "devserver@cloudstick.io"
        },
        {
          title: "Inbox",
          href: "/check-mail",
          icon: {
            element: "mail-icon"
          }
        },
        {
          title: "Compose",
          href: "/compose-mail",
          icon: {
            element: "mail-icon"
          }
        },
        {
          title: "Drafts",
          href: "/drafts",
          icon: {
            element: "mail-icon"
          }
        },
        {
          title: "Sent",
          href: "/sent-mail",
          icon: {
            element: "mail-icon"
          }
        },
         {
          title: "Spam",
          href: "/spam",
          icon: {
            element: "mail-icon"
          }
        },
         {
          title: "Trash",
          href: "/trash",
          icon: {
            element: "mail-icon"
          }
        },
         {
          title: "Archive",
          href: "/archive",
          icon: {
            element: "mail-icon"
          }
        },
        
        {
      
       },
       
         ],
      collapsed: true
    };
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      }
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    }
  }
};
</script>
