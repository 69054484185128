<template>
  <div
    class="app-wrapper"
    v-bind:class="{'sidebar-collapsed': sidebarCollapsed, 'sidebar-open-mobile': sidebarCollapsedMobile}"
  >
    <WebAppSidebar />
    <div
      class="sidebar-mobile-overlay"
      v-on:click="toggleSidebarMobile"
      v-show="sidebarCollapsedMobile"
    ></div>
    <div class="app-main">
      <transition name="fade" mode="out-in" appear>
        <Header />
      </transition>
      <div class="app-content bg-white">
        <div class="app-content--inner">
          <slot></slot>
        </div>
        <transition name="fade" mode="out-in" appear>
          <Footer />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Components/Header-admin";
import WebAppSidebar from "../Components/WebAppSidebar";
import Footer from "../Components/Footer-admin";

export default {
  name: "app",
  components: {
    Header,
    WebAppSidebar,
    Footer
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      }
    },
    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      }
    }
  },
  methods: {
    toggleSidebarMobile() {
      this.sidebarCollapsedMobile = !this.sidebarCollapsedMobile;
    }
  }
};
</script>