<template>
  <div class="app-header justify-content-between pr-2">
    <!-- <div class="d-flex">
      <button
        class="navbar-toggler hamburger hamburger--elastic toggle-sidebar"
        v-bind:class="{ 'is-active': sidebarCollapsed }"
        type="button"
        v-on:click="toggleSidebar"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
      <button
        class="
          navbar-toggler
          hamburger hamburger--elastic
          toggle-sidebar-mobile
        "
        v-bind:class="{ 'is-active': sidebarCollapsedMobile }"
        type="button"
        v-on:click="toggleSidebarMobile"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>

    </div> -->

    <div
      class="d-flex sub_heading_labels mt-2"
      style="color: #616161; font-weight: 400"
    >
      <span class="d-flex poppins_font mr-3"
        ><img
          width="20"
          class="mt-0"
          src="@/assets/img/menulightgrey.svg"
          alt
        />
      </span>
      <!-- <span class="d-flex poppins_font mr-2">
        <div class="d-40 mx-auto font-size-xl text-center header_icon">
          <b class="text-white poppins_font">NF</b>
        </div>
        <a
          href="/#/"
          class="font-weight-bold poppins_font my-auto ml-2"
          style="font-size: 18px; font-weight: 900; color: #313131"
        >
          Fronted-Design
        </a>
      </span> -->
      <span class="d-flex poppins_font mr-2"
        ><img class="mt-0" src="@/assets/img/userlightgrey.svg" alt />
        <p class="my-auto ml-2" style="color: #616161">Aswin</p>
      </span>
      <span class="d-flex poppins_font mr-2 ml-3"
        ><img class="mt-0" src="@/assets/img/phplightgrey.svg" alt />
        <p class="my-auto ml-2" style="color: #616161">7.1</p>
      </span>

      <span class="d-flex poppins_font mr-2 ml-3">
        <img
          width="20"
          class="mt-0"
          src="@/assets/img/globelightgrey.svg"
          alt
        />
        <p class="my-auto ml-1" style="">Native Nginx</p>
      </span>
      <span class="d-flex poppins_font ml-3">
        <img
          width="20"
          class="mt-0"
          src="@/assets/img/domainlightgrey.svg"
          alt
        />
        <p class="my-auto ml-2" style="">Domain Name:</p>
        <p class="my-auto mr-2 ml-2" style="color: #5c8aff">Domainname.com</p>
      </span>
    </div>
    <div class="d-flex align-items-center">
      <div class="menu-item">
        <router-link to="/backup" class="btn pl-2 pr-2"> Backup </router-link>
        <router-link to="/ticket-list" class="btn pl-2 pr-2">
          Support
        </router-link>
        <router-link to="/team" class="btn pl-2 pr-2"> Team </router-link>
        <!-- 
        <img
          class="mx-3"
          width="28"
          src="@/assets/img/header/headericon1.svg"
          alt
        />

        <img
          class="mx-3"
          width="28"
          src="@/assets/img/header/headericon2.svg"
          alt
        /> -->

        <!-- <b-form-select
          style="width: auto"
          size="sm"
          v-model="selected"
          :options="options"
        ></b-form-select> -->
      </div>
      <!-- <UserBox /> -->
      <b-dropdown
        variant="default"
        class="dropdown-menu-xl p-0 ml-2"
        right
        no-caret
      >
        <template slot="button-content">
          <div class="avatar-icon rounded avatar-icon-sm">
            <img src="@/assets/img/avatars/avatar4.jpg" alt />
          </div>
        </template>
        <div class="bg-composed-wrapper bg-asteroid">
          <div class="bg-composed-wrapper--image bg-composed-img-2"></div>
          <!-- <div class="bg-composed-wrapper--content text-light p-4">
                                    <h5 class="mb-1">Navigation</h5>
                                    <p class="mb-0 opacity-7">This is a composed background</p>
                                </div> -->
        </div>

        <div class="divider"></div>
        <div class="card-body d-block text-center">
          <div class="avatar-icon-wrapper avatar-icon-lg">
            <span class="badge badge-circle badge-success"></span>
            <div class="avatar-icon">
              <img src="@/assets/img/avatars/avatar4.jpg" alt="" />
            </div>
          </div>
          <!-- <b-button size="sm" variant="primary">
                                <span class="btn-wrapper--icon">
                                    <font-awesome-icon icon="expand-arrows-alt"/>
                                </span>
                                </b-button> -->

          <div class="d-flex justify-content-center">
            <p class="mb-0" style="font-size: 14px; font-weight: 600">
              Aswin Mohanan
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <p style="font-size: 13px; font-weight: 400; color: #83838c">
              aswinmohnan@abc.com
            </p>
          </div>
          <div class="divider"></div>
          <div class="d-flex mt-3 justify-content-center">
            <p
              class="mb-2"
              style="font-size: 13px; font-weight: 400; color: #83838c"
            >
              Account Status : Basic
            </p>
          </div>
          <b-button
            class="pl-5 pr-5"
            size="sm"
            style="box-shadow: none"
            variant="primary"
            >Upgrade to Pro</b-button
          >
          <div class="divider mt-3"></div>
          <div class="d-flex mt-3 justify-content-center">
            <p
              class="mb-2"
              style="font-size: 13px; font-weight: 400; color: #171a42"
            >
              My Profile
            </p>
          </div>
          <b-button
            class="pl-4 pr-4"
            size="sm"
            style="box-shadow: none; border: 0.5px solid #d2d2d2"
            variant="default"
            >Logout</b-button
          >
        </div>
      </b-dropdown>
    </div>
    <b-modal id="search-modal" centered>
      <div slot="modal-header">
        <h6 class="display-4 font-weight-bold">Search</h6>
        <p class="text-black-50 mb-0">Use the form below to search for files</p>
      </div>
      <div class="p-5">
        <div class="input-group">
          <input
            type="search"
            class="form-control"
            placeholder="Search terms here..."
          />
          <div class="input-group-append">
            <b-button variant="primary" class="border-0">
              <font-awesome-icon icon="search" />
            </b-button>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="d-block w-100">
        <b-button
          variant="link"
          block
          class="btn-link-dark"
          @click="$bvModal.hide('search-modal')"
          >Close search box</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
// import UserBox from "./Header/UserBox";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faCloudUploadAlt,
  faTasks,
  faEnvelope,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faSearch, faCloudUploadAlt, faTasks, faEnvelope, faUsersCog);
export default {
  name: "Header",
  components: {
    // UserBox,
    "font-awesome-icon": FontAwesomeIcon,
  },

  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "English" },
        { value: "a", text: "German" },
        { value: "b", text: "Spanish" },
        { value: "c", text: "Italian" },
      ],
    };
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      },
    },
    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      },
    },
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    toggleSidebarMobile() {
      this.sidebarCollapsedMobile = !this.sidebarCollapsedMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 991px) {
  .menu-item {
    display: none !important;
  }
  .mobile-view {
    display: block !important;
  }
}
</style>
