<template>
  <!-- <div class="app-sidebar app-sidebar--dark opensans_font" :class="sidebarbg"> -->
  <!-- app-sidebar--dark commented out / deleted for unwanted bg color in sidebar icons  -->

  <div class="app-sidebar app-sidebar--dark opensans_font" :class="sidebarbg">
    <div class="app-sidebar--header">
      <div class="nav-logo w-100 text-center">
        <router-link
          tag="a"
          class="d-block"
          active-class="active"
          to="/dashboard"
          exact
        >
          <img
            v-if="!sidebarCollapsed"
            class="logo-normal"
            src="@/assets/whitelabel/cp.svg"
            alt
          />
          <img v-else class="logo-small" src="@/assets/whitelabel/cp.svg" alt />
        </router-link>
      </div>
    </div>
    <VuePerfectScrollbar class="app-sidebar--content">
      <div class="sidebar-navigation">
        <sidebar-menu showOneChild :menu="menu" />
      </div>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import Vue from "vue";
Vue.component("user-icon", UserIcon);
Vue.component("lock-icon", LockIcon);
Vue.component("dollar-sign-icon", DollarSignIcon);
Vue.component("user-plus-icon", UserPlusIcon);
Vue.component("login-icon", LogInIcon);
Vue.component("trash-icon", TrashIcon);
Vue.component("mail-icon", MailIcon);
Vue.component("package-icon", PackageIcon);
Vue.component("home-icon", HomeIcon);
Vue.component("ticket-list-icon", ListIcon);
Vue.component("database-icon", DatabaseIcon);
Vue.component("user-check-icon", UserCheckIcon);

import { SidebarMenu } from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  UserIcon,
  LockIcon,
  DollarSignIcon,
  UserPlusIcon,
  LogInIcon,
  TrashIcon,
  MailIcon,
  PackageIcon,
  HomeIcon,
  ListIcon,
  DatabaseIcon,
  UserCheckIcon,
} from "vue-feather-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowsAltH,
  faChartLine,
  faCopy,
  faCube,
  faDollarSign,
  faInfoCircle,
  faUserPlus,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faArrowsAltH,
  faCopy,
  faWaveSquare,
  faCube,
  faInfoCircle,
  faUserPlus,
  faDollarSign,
  faChartLine
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import dashboardsImage from "@/assets/whitelabel/sidebaricons/dashboard.svg";
import myprofileImage from "@/assets/whitelabel/sidebaricons/dashboard.svg";

import referralImage from "@/assets/whitelabel/sidebaricons/accounts.svg";
import loginactivityImage from "@/assets/whitelabel/sidebaricons/log.svg";
import accountactivityImage from "@/assets/whitelabel/sidebaricons/log.svg";

// import supportImage from "@/assets/whitelabel/sidebaricons/php.svg";

export default {
  components: {
    SidebarMenu,
    VuePerfectScrollbar,
  },
  props: {
    sidebarbg: String,
  },
  data() {
    return {
      menu: [
        // {
        //   header: true,
        //   title: "My Account",
        // },
        {
          // title: "Account Activity",
          href: "/dashboard",
          icon: {
            element: "img",
            attributes: { src: dashboardsImage },
            class: "mr-4 ",
          },
        },     {
          // title: "Account Activity",
          href: "dashboard",
          icon: {
            element: "img",
            attributes: { src: myprofileImage },
            class: "mr-4 ",
          },
        },     {
          // title: "Account Activity",
          href: "/email-accounts",
          icon: {
            element: "img",
            attributes: { src: referralImage },
            class: "mr-4 ",
          },
        },     {
          // title: "Account Activity",
          href: "dashboard",
          icon: {
            element: "img",
            attributes: { src: loginactivityImage },
            class: "mr-4 ",
          },
        },     {
          // title: "Account Activity",
          href: "",
          icon: {
            element: "img",
            attributes: { src: accountactivityImage },
            class: "mr-4 ",
          },
        },     {
          // title: "Account Activity",
          href: "dashboard",
          icon: {
            element: "img",
            attributes: { src: accountactivityImage },
            class: "mr-4 ",
          },
        },
     
      ],
      collapsed: true,
    };
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      },
    },
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed; // Toggle the value
    },
  },
};
</script>
<style lang="scss" scoped>
#hat {
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>