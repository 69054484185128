<template>
  <div class="app-sidebar app-sidebar--dark opensans_font" :class="sidebarbg">

    <div class="app-sidebar--header">
      <div class="nav-logo w-100 text-center">
        <button
          class="navbar-toggler hamburger hamburger--elastic toggle-sidebar"
          v-bind:class="{ 'is-active': sidebarCollapsed }"
          type="button"
          v-on:click="toggleSidebar"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
        <button
          class="navbar-toggler hamburger hamburger--elastic toggle-sidebar-mobile"
          v-bind:class="{ 'is-active': sidebarCollapsedMobile }"
          type="button"
          v-on:click="toggleSidebarMobile"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>

        <!-- <router-link tag="a" class="d-block" active-class="active" to="/dashboard" exact>
          <img v-if="!sidebarCollapsed" class="logo-normal pl-1 pr-1 w-85" src="@/assets/img/logo-main-1.png" alt />
          <img v-else class="logo-small" src="@/assets/img/admin-logo-small.png" alt />
        </router-link> -->
      </div>
      <button
        class="toggle-sidebar rounded-circle btn btn-sm bg-white shadow-sm-dark text-primary"
        v-b-tooltip.right
        title="Expand Sidebar"
        v-bind:class="{ 'is-active': sidebarCollapsed }"
        type="button"
        v-on:click="toggleSidebar"
      >
        <font-awesome-icon icon="arrows-alt-h" />
      </button>
    </div>
    <VuePerfectScrollbar class="app-sidebar--content">
      <div class="sidebar-navigation">
        <sidebar-menu ref="sidebarMenu" showOneChild :menu="menu" />
      </div>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import Vue from "vue";
Vue.component("truck-icon", TruckIcon);
Vue.component("battery-charging-icon", BatteryChargingIcon);
Vue.component("cpu-icon", CpuIcon);
Vue.component("user-check-icon", UserCheckIcon);
Vue.component("check-circle-icon", CheckCircleIcon);
Vue.component("layout-icon", LayoutIcon);
Vue.component("paper-clip-icon", PaperclipIcon);
Vue.component("layers-icon", LayersIcon);
Vue.component("database-icon", DatabaseIcon);
Vue.component("briefcase-icon", BriefcaseIcon);
Vue.component("map-pin-icon", MapPinIcon);
Vue.component("pie-chart-icon", PieChartIcon);
Vue.component("key-icon", KeyIcon);
Vue.component("users-icon", UsersIcon);
Vue.component("home-icon", HomeIcon);
Vue.component("clock-icon", ClockIcon);
Vue.component("bell-icon", BellIcon);
Vue.component("server-icon", ServerIcon);
Vue.component("lock-icon", LockIcon);
Vue.component("settings-icon", SettingsIcon);
Vue.component("code-icon", CodeIcon);
Vue.component("globe-icon", GlobeIcon);
Vue.component("folder-icon", FolderIcon);
Vue.component("github-icon", GithubIcon);
Vue.component("activity-icon", ActivityIcon);
Vue.component("aperture-icon", ApertureIcon);
Vue.component("anchor-icon", AnchorIcon);
Vue.component("upload-cloud-icon", UploadCloudIcon);
Vue.component("share-icon", ShareIcon);
Vue.component("terminal-icon", TerminalIcon);
Vue.component("refresh-cw-icon", RefreshCwIcon);
Vue.component("hard-drive-icon", HardDriveIcon);
Vue.component("file-icon", FileIcon);
Vue.component("trash-2-icon", Trash2Icon);

import { SidebarMenu } from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  TruckIcon,
  BatteryChargingIcon,
  CpuIcon,
  UserCheckIcon,
  CheckCircleIcon,
  LayoutIcon,
  PaperclipIcon,
  LayersIcon,
  DatabaseIcon,
  BriefcaseIcon,
  MapPinIcon,
  PieChartIcon,
  KeyIcon,
  UsersIcon,
  HomeIcon,
  ClockIcon,
  BellIcon,
  ServerIcon,
  LockIcon,
  SettingsIcon,
  CodeIcon,
  GlobeIcon,
  FolderIcon,
  GithubIcon,
  ActivityIcon,
  ApertureIcon,
  AnchorIcon,
  UploadCloudIcon,
  ShareIcon,
  TerminalIcon,
  RefreshCwIcon,
  HardDriveIcon,
  FileIcon,
  Trash2Icon,
} from "vue-feather-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowsAltH,
  faBriefcase,
  faChartLine,
  faCogs,
  faCopy,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPhp } from "@fortawesome/free-brands-svg-icons";

library.add(
  faArrowsAltH,
  faCopy,
  faWaveSquare,
  faChartLine,
  faPhp,
  faBriefcase,
  faCogs
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import dashboardsImage from "@/assets/img/menunew/dashboards.svg";
import activitylogsImage from "@/assets/img/menunew/activitylogs.svg";
import accountsImage from "@/assets/img/menunew/accounts.svg";
import databasesImage from "@/assets/img/menunew/databases.svg";
import cronjobsImage from "@/assets/img/menunew/cronjobs.svg";
import easyphpImage from "@/assets/img/menunew/easyphp.svg";
import sshkeyImage from "@/assets/img/menunew/sshkeys.svg";
import securityImage from "@/assets/img/menunew/security.svg";
import servicesImage from "@/assets/img/menunew/services.svg";
import systemusersImage from "@/assets/img/menunew/systemusers.svg";
import supervisorjobsImage from "@/assets/img/menunew/supervisorjobs.svg";
import serverstatsImage from "@/assets/img/menunew/serverstats.svg";

export default {
  components: {
    SidebarMenu,
    VuePerfectScrollbar,
    "font-awesome-icon": FontAwesomeIcon,
  },
  props: {
    sidebarbg: String,
  },
  data() {
    return {
      menu: [
        {
          header: true,
          title: "Dashboards",
        },
        {
          title: "Dashboard",
          href: "/dashboard",
          icon: {
            element: "img",
            attributes: { src: dashboardsImage },
            class: "mr-4",
          },
          // icon: {
          //   element: 'font-awesome-icon',
          //   attributes: {
          //     icon: 'table',
          //     size: 'sm'
          //   },
          // },
        },
        {
          title: "Activity logs",
          href: "/activity-logs/",
          icon: {
            element: "img",
            attributes: { src: activitylogsImage },
            class: "mr-4 ",
          },
        },
        {
          title: "Accounts",
          href: "/web-applications/",
          icon: {
            element: "img",
            attributes: { src: accountsImage },
            class: "mr-4 ",
          },
        },
        {
          href: "/database-list/",
          title: "Databases",
          icon: {
            element: "img",
            attributes: { src: databasesImage },
            class: "mr-4 ",
          },
        },
        {
          href: "/cron-job/",
          title: "Cron Jobs",
          icon: {
            element: "img",
            attributes: { src: cronjobsImage },
            class: "mr-4 ",
          },
        },
        {
          href: "/easyphp/",
          title: "EasyPHP",
          icon: {
            element: "img",
            attributes: { src: easyphpImage },
            class: "mr-4",
          },
        },
        {
          href: "/ssh-keys/",
          title: "SSH Key",
          icon: {
            element: "img",
            attributes: { src: sshkeyImage },
            class: "mr-4",
          },
        },
        {
          href: "/security/",
          title: "Security",
          icon: {
            element: "img",
            attributes: { src: securityImage },
            class: "mr-4",
          },
        },
        {
          href: "/services/",
          title: "Services",
          icon: {
            element: "img",
            attributes: { src: servicesImage },
            class: "mr-4",
          },
        },
        {
          href: "/system-users/",
          title: "System Users",
          icon: {
            element: "img",
            attributes: { src: systemusersImage },
            class: "mr-4",
          },
        },
        {
          href: "/server-stats/",
          title: "Server Stats",
          icon: {
            element: "img",
            attributes: { src: serverstatsImage },
            class: "mr-4",
          },
        },
        {
          href: "/supervisor-job/",
          title: "Supervisor Jobs",
          icon: {
            element: "img",
            attributes: { src: supervisorjobsImage },
            class: "mr-4",
          },
        },
      ],
      idParam: "",
      id: "",
      collapsed: true,
    };
  },
  // created() {
  //   this.idParam = this.$route.params.id
  //   if(this.idParam && this.idParam.split('/').length === 2) {
  //     this.id = this.idParam.split('/')[0]
  //   } else {
  //     this.id = this.idParam
  //   }
  //   for(let item = 2; item < this.menu.length; item++) {
  //     this.menu[item].href = `${this.menu[item].href}${this.id}`
  //   }
  //   this.getServerById()
  // },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      },
    },
    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      },
    },
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    toggleSidebarMobile() {
      this.sidebarCollapsedMobile = !this.sidebarCollapsedMobile;
    },
    async getServerById() {
      try {
        await this.$store.dispatch("getServerById", this.id);
      } catch (error) {
        // console.log(error)
      }
    },
  },
};
</script>

// <style lang="scss" scoped>
#hat {
  position: absolute;
  left: 0;
  bottom: 0;
}
// .:hover {
//   -ms-transform: scale(1.5); /* IE 9 */
//   -webkit-transform: scale(1.5); /* Safari 3-8 */
//   transform: scale(1.5);
// }
//
</style>
