<template>
  <div class="admin-app-content bg-white">
    <div class="app-content--inner">
      <slot></slot>
    </div>
    <div class="app-footer font-size-sm text-black-50">
      <div>
        © 2020 - CloudStick LLC - created by
        <a
          href="https://cloudstick.io"
          title="UiFort.com"
          target="_blank"
        >CloudStick.io</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer-admin",
  components: {}
};
</script>
