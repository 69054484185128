<template>
  <div class="app-header">
    <div class="d-flex">
      <button
        class="navbar-toggler hamburger hamburger--elastic toggle-sidebar"
        v-bind:class="{'is-active': sidebarCollapsed}"
        type="button"
        v-on:click="toggleSidebar"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
      <button
        class="navbar-toggler hamburger hamburger--elastic toggle-sidebar-mobile"
        v-bind:class="{'is-active': sidebarCollapsedMobile}"
        type="button"
        v-on:click="toggleSidebarMobile"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
      <div>
        <router-link to="/admin/users" class="btn mr-1">
          <font-awesome-icon icon="users" class="mr-1" />Users
        </router-link>
        <router-link to="/admin/servers" class="btn mr-1">
          <font-awesome-icon icon="server" class="mr-1" />Servers
        </router-link>
        <router-link to="/admin/packages" class="btn mr-1">
          <font-awesome-icon icon="cube" class="mr-1" />Packages
        </router-link>
        <router-link to="#" class="btn mr-1">
          <font-awesome-icon icon="file" class="mr-1" />Reports
        </router-link>
        <router-link to="/admin/api" class="btn mr-1">
          <font-awesome-icon icon="key" class="mr-1" />API
        </router-link>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <UserBox />
    </div>
    <b-modal id="search-modal" centered>
      <div slot="modal-header">
        <h6 class="display-4 font-weight-bold">Search</h6>
        <p class="text-black-50 mb-0">Use the form below to search for files</p>
      </div>
      <div class="p-5">
        <div class="input-group">
          <input type="search" class="form-control" placeholder="Search terms here..." />
          <div class="input-group-append">
            <b-button variant="primary" class="border-0">
              <font-awesome-icon icon="search" />
            </b-button>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="d-block w-100">
        <b-button
          variant="link"
          block
          class="btn-link-dark"
          @click="$bvModal.hide('search-modal')"
        >Close search box</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import UserBox from "./Header/UserBox";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faSearch,
  faCloudUploadAlt,
  faTasks,
  faEnvelope,
  faUsersCog,
  faAngleDown,
  faCube,
  faPuzzlePiece,
  faFile,
  faSync,
  faUsers,
  faServer,
  faKey
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faSearch, faCloudUploadAlt, faTasks, faEnvelope, faUsersCog);
library.add(faAngleDown);
library.add(faAngleUp);
library.add(faTimes);
library.add(faCube);
library.add(faPuzzlePiece);
library.add(faPuzzlePiece);
library.add(faPuzzlePiece);
library.add(faFile);
library.add(faSync);
library.add(faUsers);
library.add(faServer);
library.add(faKey);
export default {
  name: "Header-admin",
  components: {
    UserBox,
    "font-awesome-icon": FontAwesomeIcon
  },

  data() {
    return {};
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      }
    },
    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      }
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    toggleSidebarMobile() {
      this.sidebarCollapsedMobile = !this.sidebarCollapsedMobile;
    },
    showTooltip() {
      this.$refs.tooltipSuccess._toolpop
        .getTipElement()
        .classList.add("tooltip-success");
    },
    showPopoverIntegrations() {
      this.$refs.userBoxPopover._toolpop
        .getTipElement()
        .classList.add(
          "popover-custom-wrapper",
          "popover-custom-lg",
          "popover-secondary"
        );
    }
  }
};
</script>
