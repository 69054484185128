<template>
  <div class="app-sidebar app-sidebar--dark app-sidebar--dark">
    <div class="app-sidebar--header">
      <div class="nav-logo w-100 text-center">
        <router-link tag="a" class="d-block" active-class="active" to="/">
          <img class="logo-small" src="@/assets/img/admin-logo-small.png" alt />
        </router-link>
      </div>
    </div>
    <VuePerfectScrollbar class="app-sidebar--content">
      <div class="sidebar-navigation--mini">
        <ul>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="'/web-applications/'"
              v-b-tooltip.right
              title="Summary"
            >
              <span>
                <layers-icon></layers-icon>
              </span>
            </router-link>
          </li>
          <!-- <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="'/activity-logs/' + id"
              v-b-tooltip.right
              title="Traffic Stats"
            >
              <span>
                <activity-icon></activity-icon>
              </span>
            </router-link>
          </li>           -->
          <!-- <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="'/create-account/' + serverId"
              v-b-tooltip.right
              title="Script installer"
            >
              <span>
                <code-icon></code-icon>
              </span>
            </router-link>
          </li> -->
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="`/git/`"
              v-b-tooltip.right
              title="Git"
            >
              <span>
                <github-icon></github-icon>
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="`/file-manager/`"
              v-b-tooltip.right
              title="File manager"
            >
              <span>
                <folder-icon></folder-icon>
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="`/ftp-accounts/`"
              v-b-tooltip.right
              title="FTP accounts"
            >
              <span>
                <truck-icon></truck-icon>
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="`/domains`"
              v-b-tooltip.right
              title="Domain Name"
            >
              <span>
                <globe-icon></globe-icon>
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="`/ssl/`"
              v-b-tooltip.right
              title="SSL TLS"
            >
              <span>
                <key-icon></key-icon>
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="`/sub-domains`"
              v-b-tooltip.right
              title="Sub Domains"
            >
              <span>
                <globe-icon></globe-icon>
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="`/web-server-log`"
              v-b-tooltip.right
              title="Web Server Log"
            >
              <span>
                <aperture-icon></aperture-icon>
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="`/webapp-settings`"
              v-b-tooltip.right
              title="Settings"
            >
              <span>
                <settings-icon></settings-icon>
              </span>
            </router-link>
          </li>
          <!-- <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              :to="`/activity-logs/${serverId}/${id}`"
              v-b-tooltip.right
              title="Activity Log"
            >
              <span>
                <activity-icon></activity-icon>
              </span>
            </router-link>
          </li> -->
        </ul>
        <div class="text-center">
          <router-link
            tag="a"
            active-class="active"
            exact
            :to="`/account-summary`"
            id="tooltipBack"
            v-b-tooltip.right
            class="m-1 btn btn-warning p-0 d-inline-block shadow-none text-center font-size-lg d-40 rounded"
          >
            <font-awesome-icon icon="arrow-left" />
          </router-link>
          <b-tooltip           
            ref="tooltipWarning"
            placement="right"
            target="tooltipBack"
          >Back to summary</b-tooltip>
        </div>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import Vue from "vue";

Vue.component("truck-icon", TruckIcon);
Vue.component("battery-charging-icon", BatteryChargingIcon);
Vue.component("cpu-icon", CpuIcon);
Vue.component("user-check-icon", UserCheckIcon);
Vue.component("database-icon", DatabaseIcon);
Vue.component("map-pin-icon", MapPinIcon);
Vue.component("pie-chart-icon", PieChartIcon);

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  TruckIcon,
  BatteryChargingIcon,
  CpuIcon,
  UserCheckIcon,
  DatabaseIcon,
  MapPinIcon,
  PieChartIcon
} from "vue-feather-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowLeft);

export default {
  components: {
    VuePerfectScrollbar,
    "font-awesome-icon": FontAwesomeIcon
  },
  methods: {
    showTooltip() {
      this.$refs.tooltipWarning._toolpop
        .getTipElement()
        .classList.add("tooltip-warning");
    }
  }
};
</script>
