<template>
  <div class="app-sidebar app-sidebar--dark" :class="sidebarbg">
    <div class="app-sidebar--header">
      <div class="nav-logo w-100 text-center">
        <router-link tag="a" class="d-block" active-class="active" to="/" exact>
          <img
            v-if="!sidebarCollapsed"
            class="logo-normal pl-1 pr-1 w-85"
            src="@/assets/img/logo-main-1.png"
            alt
          />
          <img
            v-else
            class="logo-small"
            src="@/assets/img/admin-logo-small.png"
            alt
          />
        </router-link>
      </div>
      <button
        class="
          toggle-sidebar
          rounded-circle
          btn btn-sm
          bg-white
          shadow-sm-dark
          text-primary
        "
        v-b-tooltip.right
        title="Expand Sidebar"
        v-bind:class="{ 'is-active': sidebarCollapsed }"
        type="button"
        v-on:click="toggleSidebar"
      >
        <font-awesome-icon icon="arrows-alt-h" />
      </button>
    </div>
    <VuePerfectScrollbar class="app-sidebar--content">
      <div class="sidebar-navigation">
        <sidebar-menu showOneChild :menu="menu" />
      </div>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import Vue from "vue";
Vue.component("truck-icon", TruckIcon);
Vue.component("battery-charging-icon", BatteryChargingIcon);
Vue.component("cpu-icon", CpuIcon);
Vue.component("user-check-icon", UserCheckIcon);
Vue.component("check-circle-icon", CheckCircleIcon);
Vue.component("layout-icon", LayoutIcon);
Vue.component("paper-clip-icon", PaperclipIcon);
Vue.component("layers-icon", LayersIcon);
Vue.component("database-icon", DatabaseIcon);
Vue.component("briefcase-icon", BriefcaseIcon);
Vue.component("map-pin-icon", MapPinIcon);
Vue.component("pie-chart-icon", PieChartIcon);
Vue.component("key-icon", KeyIcon);
Vue.component("users-icon", UsersIcon);
Vue.component("home-icon", HomeIcon);
Vue.component("clock-icon", ClockIcon);
Vue.component("bell-icon", BellIcon);
Vue.component("server-icon", ServerIcon);
Vue.component("lock-icon", LockIcon);
Vue.component("settings-icon", SettingsIcon);
Vue.component("code-icon", CodeIcon);
Vue.component("globe-icon", GlobeIcon);
Vue.component("folder-icon", FolderIcon);
Vue.component("github-icon", GithubIcon);
Vue.component("activity-icon", ActivityIcon);
Vue.component("aperture-icon", ApertureIcon);
Vue.component("arrow-left-icon", ArrowLeftIcon);
Vue.component("mail-icon", MailIcon);
Vue.component("disc-icon", DiscIcon);
Vue.component("forw-icon", FastForwardIcon);
Vue.component("responder-icon", RepeatIcon);
Vue.component("accounts-icon", UsersIcon);

import { SidebarMenu } from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  TruckIcon,
  BatteryChargingIcon,
  CpuIcon,
  UserCheckIcon,
  CheckCircleIcon,
  LayoutIcon,
  PaperclipIcon,
  LayersIcon,
  DatabaseIcon,
  BriefcaseIcon,
  MapPinIcon,
  PieChartIcon,
  KeyIcon,
  UsersIcon,
  HomeIcon,
  ClockIcon,
  BellIcon,
  ServerIcon,
  LockIcon,
  SettingsIcon,
  CodeIcon,
  GlobeIcon,
  FolderIcon,
  GithubIcon,
  ActivityIcon,
  ApertureIcon,
  ArrowLeftIcon,
  MailIcon,
  DiscIcon,
  FastForwardIcon,
  RepeatIcon,
} from "vue-feather-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowRight,
  faArrowsAltH,
  faCompactDisc,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowsAltH, faArrowRight, faCompactDisc);
import accountsummaryImage from '@/assets/img/menunew/accountsummary.svg'
import listaccountsImage from '@/assets/img/menunew/listaccounts.svg'
import gitImage from '@/assets/img/menunew/git.svg'
import filemanagerImage from '@/assets/img/menunew/filemanager.svg'
import ftpaccountsImage from '@/assets/img/menunew/ftpaccounts.svg'
import domainnameImage from '@/assets/img/menunew/domainname.svg'
import managesslImage from '@/assets/img/menunew/managessl.svg'
import appdatabaseImage from '@/assets/img/menunew/appdatabase.svg'
import subdomainsImage from '@/assets/img/menunew/subdomains.svg'
import settingsImage from '@/assets/img/menunew/settings.svg'
import webserverlogImage from '@/assets/img/menunew/webserverlog.svg'
import cachecontrolImage from '@/assets/img/menunew/cachecontrol.svg'













export default {
  components: {
    SidebarMenu,
    VuePerfectScrollbar,
    "font-awesome-icon": FontAwesomeIcon,
  },
  props: {
    sidebarbg: String,
  },
  data() {
    return {
      menu: [
        {
          header: true,
          title: "Server Summary",
        },
        {
          title: "Account Summary",
          href: "/server-summary",
             icon: {
            element: "img",
            attributes: { src: accountsummaryImage },
            class: "mr-4",
          },
        },
        {
          title: "List Accounts",
          href: "/web-applications",
     icon: {
            element: "img",
            attributes: { src: listaccountsImage },
            class: "mr-4",
          },
        },
        {
          href: "/git",
          title: "Git",
             icon: {
            element: "img",
            attributes: { src: gitImage },
            class: "mr-4",
          },
        },

        {
          href: "/file-manager",
          title: "File manager",
            icon: {
            element: "img",
            attributes: { src: filemanagerImage },
            class: "mr-4",
          },
        },
        {
          href: "/ftp-accounts",
          title: "FTP accounts",
           icon: {
            element: "img",
            attributes: { src: ftpaccountsImage },
            class: "mr-4",
          },
        },
        {
          href: "/domains",
          title: "Domain Name",
            icon: {
            element: "img",
            attributes: { src: domainnameImage },
            class: "mr-4",
          },
        },
        {
          href: "/ssl-certificates",
          title: "Manage SSL",
            icon: {
            element: "img",
            attributes: { src: managesslImage },
            class: "mr-4",
          },
        },
        {
          href: "/app-database",
          title: "App Databases",
           icon: {
            element: "img",
            attributes: { src: appdatabaseImage },
            class: "mr-4",
          },
        },
        {
          href: "/sub-domains",
          title: "Sub Domains",
            icon: {
            element: "img",
            attributes: { src: subdomainsImage },
            class: "mr-4",
          },
        },
        {
          href: "/webapp-settings",
          title: "Settings",
            icon: {
            element: "img",
            attributes: { src: settingsImage },
            class: "mr-4",
          },
        },

        {
          href: "/web-server-log",
          title: "Web Server Log",
             icon: {
            element: "img",
            attributes: { src: webserverlogImage },
            class: "mr-4",
          },
        },
        {
          href: "/cache-control",
          title: "Cache-Control",
         icon: {
            element: "img",
            attributes: { src: cachecontrolImage },
            class: "mr-4",
          },
        },

      ],
      collapsed: true,
    };
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      },
    },
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
  },
};
</script>
