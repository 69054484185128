<template>
  <div class="app-header justify-content-between  pr-2">
    <div class="d-flex">
      <button
        class="navbar-toggler hamburger hamburger--elastic toggle-sidebar"
        v-bind:class="{ 'is-active': sidebarCollapsed }"
        type="button"
        v-on:click="toggleSidebar"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
      <button
        class="
          navbar-toggler
          hamburger hamburger--elastic
          toggle-sidebar-mobile
        "
        v-bind:class="{ 'is-active': sidebarCollapsedMobile }"
        type="button"
        v-on:click="toggleSidebarMobile"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
      <!-- <div class="search-link">
        <div class="d-none d-lg-flex align-items-center">
          <font-awesome-icon icon="search" class="search-icon" />
          <input type="text" placeholder="Click here to search..." />
        </div>
        <div class="d-none d-sm-flex d-lg-none">
          <b-button variant="outline-primary" size="sm" class="px-3" v-b-modal.search-modal>
            <font-awesome-icon icon="search" />
          </b-button>
        </div>
      </div> -->
    </div>
    <div class="d-flex align-items-center">
      <div class="menu-item">
            <router-link to="/" style="color:#1778BE;box-shadow:none;" class="btn pl-2 opensans_font pr-2"> 12345-3481345</router-link>

        <router-link to="/backup" style="box-shadow:none;" class="btn pl-2 opensans_font pr-2"> Backup</router-link>
        <router-link to="/dns" style="box-shadow:none;" class="btn pl-2 pr-2 opensans_font">
          DNS
        </router-link>
        <router-link to="/team" class="btn opensans_font pl-2 pr-2"> Team </router-link>

        <img
          class="mx-3"
          src="@/assets/img/header/headericon1orange.svg"
          alt
        />

        <img
          class="mx-3"
          src="@/assets/img/header/headericon2orange.svg"
          alt
        />

        <b-form-select
          style="width: auto"
          size="sm"
          v-model="selected"
          :options="options"
        ></b-form-select>
      </div>
      <!-- <UserBox /> -->
      <b-dropdown
        variant="default"
        class="dropdown-menu-xl  p-0 ml-2"
        right
        no-caret
      >
        <template slot="button-content" >
           <div class="avatar-icon-wrapper avatar-icon-md">
            <span class="badge badge-circle badge-success"></span>
            <div class="avatar-icon">
              <img src="@/assets/img/avatars/avatar4.jpg" alt="" />
            </div>
          </div>
        </template>
        <div class="bg-composed-wrapper bg-asteroid">
          <div class="bg-composed-wrapper--image bg-composed-img-2"></div>
          <!-- <div class="bg-composed-wrapper--content text-light p-4">
                                    <h5 class="mb-1">Navigation</h5>
                                    <p class="mb-0 opacity-7">This is a composed background</p>
                                </div> -->
        </div>

        <div class="divider"></div>
        <div class="card-body d-block text-center">
          <div class="avatar-icon-wrapper avatar-icon-lg">
            <span class="badge badge-circle badge-success"></span>
            <div class="avatar-icon">
              <img src="@/assets/img/avatars/avatar4.jpg" alt="" />
            </div>
          </div>
          <!-- <b-button size="sm" variant="primary">
                                <span class="btn-wrapper--icon">
                                    <font-awesome-icon icon="expand-arrows-alt"/>
                                </span>
                                </b-button> -->

          <div class="d-flex justify-content-center">
            <p class="mb-0" style="font-size: 14px; font-weight: 600">
              Aswin Mohanan
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <p style="font-size: 13px; font-weight: 400; color: #83838c">
              aswinmohnan@abc.com
            </p>
          </div>
          <div class="divider"></div>
          <div class="d-flex mt-3 justify-content-center">
            <p class="mb-2" style="font-size: 13px; font-weight: 400; color: #83838c">
              Account Status : Basic
            </p>
          </div>
          <b-button class="pl-5 pr-5" size="sm" style="box-shadow: none" variant="primary"
            >Upgrade to Pro</b-button
          >
          <div class="divider mt-3"></div>
          <div class="d-flex mt-3 justify-content-center">
            <p class="mb-2" style="font-size: 13px; font-weight: 400; color: #171a42">
              My Profile
            </p>
          </div>
          <b-button class="pl-4 pr-4" size="sm" style="box-shadow: none;border: 0.5px solid #D2D2D2" variant="default"
            >Logout</b-button
          >
        </div>
      </b-dropdown>
    </div>
    <b-modal id="search-modal" centered>
      <div slot="modal-header">
        <h6 class="display-4 font-weight-bold">Search</h6>
        <p class="text-black-50 mb-0">Use the form below to search for files</p>
      </div>
      <div class="p-5">
        <div class="input-group">
          <input
            type="search"
            class="form-control"
            placeholder="Search terms here..."
          />
          <div class="input-group-append">
            <b-button variant="primary" class="border-0">
              <font-awesome-icon icon="search" />
            </b-button>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="d-block w-100">
        <b-button
          variant="link"
          block
          class="btn-link-dark"
          @click="$bvModal.hide('search-modal')"
          >Close search box</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
// import UserBox from "./Header/UserBox";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faCloudUploadAlt,
  faTasks,
  faEnvelope,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faSearch, faCloudUploadAlt, faTasks, faEnvelope, faUsersCog);
export default {
  name: "Header",
  components: {
    // UserBox,
    "font-awesome-icon": FontAwesomeIcon,
  },

  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "English" },
        { value: "a", text: "German" },
        { value: "b", text: "Spanish" },
        { value: "c", text: "Italian" },
      ],
    };
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      },
    },
    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      },
    },
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    toggleSidebarMobile() {
      this.sidebarCollapsedMobile = !this.sidebarCollapsedMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 991px) {
  .menu-item {
    display: none !important;
  }
  .mobile-view {
    display: block !important;
  }
}
</style>
