<template>
  <div class="app-sidebar app-sidebar--dark app-sidebar--dark">
    <div class="app-sidebar--header">
      <div class="nav-logo w-100 text-center">
        <router-link tag="a" class="d-block" active-class="active" to="/">
          <img class="logo-small" src="@/assets/img/admin-logo-small.png" alt />
        </router-link>
      </div>
    </div>
    <VuePerfectScrollbar class="app-sidebar--content">
      <div class="sidebar-navigation--mini">
        <ul>
         
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              to="/check-mail"
              v-b-tooltip.right
              title="Inbox"
            >
              <span>
                <mail-icon></mail-icon>
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              to="/autoresponders"
              v-b-tooltip.right
              title="Autoresponders"
            >
              <span>
                <mail-icon></mail-icon>
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              to="/forwarders"
              v-b-tooltip.right
              title="Forwarders"
            >
              <span>
                <mail-icon></mail-icon>
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              tag="a"
              active-class="active"
              exact
              to="/email-disk-usage"
              v-b-tooltip.right
              title="Email Disk Usage"
            >
              <span>
                <mail-icon></mail-icon>
              </span>
            </router-link>
          </li>
          
        </ul>
        <div class="text-center">
          <router-link
            tag="a"
            active-class="active"
            exact
            to="/email-accounts"
            id="tooltipBack"
            v-b-tooltip.right
            class="m-1 btn btn-primary p-0 d-inline-block shadow-none text-center font-size-lg d-40 rounded"
          >
            <font-awesome-icon icon="arrow-left" />
          </router-link>
          <b-tooltip
            @show="showTooltip"
            ref="tooltipWarning"
            placement="right"
            target="tooltipBack"
          >Back to summary</b-tooltip>
        </div>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import Vue from "vue";

Vue.component("truck-icon", TruckIcon);
Vue.component("battery-charging-icon", BatteryChargingIcon);
Vue.component("cpu-icon", CpuIcon);
Vue.component("user-check-icon", UserCheckIcon);
Vue.component("database-icon", DatabaseIcon);
Vue.component("map-pin-icon", MapPinIcon);
Vue.component("pie-chart-icon", PieChartIcon);
Vue.component("mail-icon", MailIcon);

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  TruckIcon,
  BatteryChargingIcon,
  CpuIcon,
  UserCheckIcon,
  DatabaseIcon,
  MapPinIcon,
  PieChartIcon,MailIcon
  
} from "vue-feather-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowLeft);

export default {
  components: {
    VuePerfectScrollbar,
    "font-awesome-icon": FontAwesomeIcon
  },
  methods: {
    showTooltip() {
      this.$refs.tooltipWarning._toolpop
        .getTipElement()
        .classList.add("tooltip-warning");
    }
  }
};
</script>
