<template>
  <div id="app" class="d-flex flex-fill">
      <component :is="layout">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </component>


  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

const default_layout = "default";

export default {
  VuePerfectScrollbar,

  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>

<style lang="scss" >
/* eslint-disable */

.gradient_btn {
  background: linear-gradient(91.21deg, #1a6aa4 1.32%, #00bea6 100%);
}

@import "assets/bamburgh.scss";
</style>
