<template>
  <div class="app-sidebar app-sidebar--dark" :class="sidebarbg">
    <div class="app-sidebar--header">
      <div class="nav-logo w-100 text-center">
        <router-link tag="a" class="d-block" active-class="active" to="/dashboard" exact>
          <img v-if="!sidebarCollapsed" class="logo-normal pl-1 pr-1 w-85" src="@/assets/img/cslogonew1.svg" alt />
          <img v-else class="logo-small" src="@/assets/img/cslogonew1.svg" alt />
        </router-link>
      </div>
      <button
        class="toggle-sidebar rounded-circle btn btn-sm bg-white shadow-sm-dark text-primary"
        v-b-tooltip.right
        title="Expand Sidebar"
        v-bind:class="{'is-active': sidebarCollapsed}"
        type="button"
        v-on:click="toggleSidebar"
      >
        <font-awesome-icon icon="arrows-alt-h" />
      </button>
    </div>
    <VuePerfectScrollbar class="app-sidebar--content">
      <div class="sidebar-navigation">
        <sidebar-menu showOneChild :menu="menu" />
      </div>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import Vue from "vue";
Vue.component("truck-icon", TruckIcon);
Vue.component("battery-charging-icon", BatteryChargingIcon);
Vue.component("cpu-icon", CpuIcon);
Vue.component("user-check-icon", UserCheckIcon);
Vue.component("check-circle-icon", CheckCircleIcon);
Vue.component("layout-icon", LayoutIcon);
Vue.component("paper-clip-icon", PaperclipIcon);
Vue.component("layers-icon", LayersIcon);
Vue.component("database-icon", DatabaseIcon);
Vue.component("briefcase-icon", BriefcaseIcon);
Vue.component("map-pin-icon", MapPinIcon);
Vue.component("pie-chart-icon", PieChartIcon);
Vue.component("key-icon", KeyIcon);
Vue.component("users-icon", UsersIcon);
Vue.component("home-icon", HomeIcon);
Vue.component("clock-icon", ClockIcon);
Vue.component("bell-icon", BellIcon);
Vue.component("server-icon", ServerIcon);
Vue.component("lock-icon", LockIcon);
Vue.component("settings-icon", SettingsIcon);
Vue.component("code-icon", CodeIcon);
Vue.component("globe-icon", GlobeIcon);
Vue.component("folder-icon", FolderIcon);
Vue.component("github-icon", GithubIcon);
Vue.component("activity-icon", ActivityIcon);
Vue.component("aperture-icon", ApertureIcon);

import { SidebarMenu } from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  TruckIcon,
  BatteryChargingIcon,
  CpuIcon,
  UserCheckIcon,
  CheckCircleIcon,
  LayoutIcon,
  PaperclipIcon,
  LayersIcon,
  DatabaseIcon,
  BriefcaseIcon,
  MapPinIcon,
  PieChartIcon,
  KeyIcon,
  UsersIcon,
  HomeIcon,
  ClockIcon,
  BellIcon,
  ServerIcon,
  LockIcon,
  SettingsIcon,
  CodeIcon,
  GlobeIcon,
  FolderIcon,
  GithubIcon,
  ActivityIcon,
  ApertureIcon
} from "vue-feather-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowsAltH);

export default {
  components: {
    SidebarMenu,
    VuePerfectScrollbar,
    "font-awesome-icon": FontAwesomeIcon
  },
  props: {
    sidebarbg: String
  },
  data() {
    return {
      menu: [
        {
          header: true,
          title: "Dashboards"
        },
        {
          title: "Dashboard",
          href: "/dashboard",
          icon: {
            element: "home-icon"
          }
        },
        {
          title: "Activity logs",
          href: "/activity-logs",
          icon: {
            element: "activity-icon"
          }
        },
        {
          href: "/cron-job",
          title: "Cron Job",
          icon: {
            element: "clock-icon"
          }
        },
        {
          href: "/database-list",
          title: "Databases",
          icon: {
            element: "database-icon"
          }
        },
        // {
        //   href: "/deployment-keys",
        //   title: "Deployment Key",
        //   icon: {
        //     element: "key-icon"
        //   }
        // },
         {
          href: "/easyphp",
          title: "Easyphp",
          icon: {
            element: "key-icon"
          }
        },
        {
          href: "/ssh-keys",
          title: "SSH Key",
          icon: {
            element: "key-icon"
          }
        },
              {
          href: "/web-applications",
          title: "Web Applications",
          icon: {
            element: "aperture-icon"
          }
        },

        // {
        //   href: "/notifications",
        //   title: "Notifications",
        //   icon: {
        //     element: "bell-icon"
        //   }
        // },
        // {
        //   href: "/php-cli",
        //   title: "PHP CLI",
        //   icon: {
        //     element: "terminal-icon"
        //   }
        // },
       
        // {
        //   href: "/choose-web-app",
        //   title: "Script installer",
        //   icon: {
        //     element: "code-icon"
        //   }
        // },
        // {
        //   href: "/services",
        //   title: "Services",
        //   icon: {
        //     element: "server-icon"
        //   }
        // },
        // {
        //   href: "/security",
        //   title: "Security",
        //   icon: {
        //     element: "lock-icon"
        //   }
        // },
        // {
        //   href: "/supervisor-job",
        //   title: "Supervisor Job",
        //   icon: {
        //     element: "user-check-icon"
        //   }
        // },
        // {
        //   href: "/user-list",
        //   title: "System Users",
        //   icon: {
        //     element: "users-icon"
        //   }
        // },
  
        // {
        //   href: "/web-server-log",
        //   title: "Web Server Log",
        //   icon: {
        //     element: "anchor-icon"
        //   }
        // },
        // {
        //   href: "/server-stats",
        //   title: "Server Stats",
        //   icon: {
        //     element: "activity-icon"
        //   }
        // }
      ],
      collapsed: true
    };
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      }
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    }
  }
};
</script>
