<template>
  <div
    class="app-wrapper"
    v-bind:class="{'sidebar-collapsed': sidebarCollapsed, 'sidebar-open-mobile': sidebarCollapsedMobile}"
  >
    
    <div
      class="sidebar-mobile-overlay"
      v-on:click="toggleSidebarMobile"
      v-show="sidebarCollapsedMobile"
    ></div>
    <div class="app-main">
     
      <div class="app-content bg-white pt-0">
        <div class="app-content--inner pt-0">
          <!-- <button
        class="navbar-toggler hamburger hamburger--elastic toggle-sidebar"
        v-bind:class="{ 'is-active': sidebarCollapsed }"
        type="button"
        v-on:click="toggleSidebar"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button> -->
      <div class="collapsedbutton">
      <button
        class="navbar-toggler hamburger hamburger--elastic toggle-sidebar-mobile "
        v-bind:class="{ 'is-active': sidebarCollapsedMobile }"
        type="button"
        v-on:click="toggleSidebarMobile"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
          <slot></slot>
        </div>
        
        
        <transition name="fade" mode="out-in" appear>
          <Footer />
        </transition>
      </div>
    </div>
    <ProfileSidebar />
  </div>
</template>

<script>
import ProfileSidebar from "../Components/ProfileSidebar";
import Footer from "../Components/Footer";

export default {
  name: "app",
  components: {
    ProfileSidebar,
    Footer
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      }
    },
    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      }
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    toggleSidebarMobile() {
      this.sidebarCollapsedMobile = !this.sidebarCollapsedMobile;
    },
  }
};
</script>
<style lang="scss" scoped>
.collapsedbutton{
  display: none !important;

}
@media only screen and (max-width: 991px) {
  .collapsedbutton {
    display: block !important;
  }

}
</style>