<template>
  <div class="app-header justify-content-between opensans_font pr-2">
    <div class="d-flex">
      <button
        class="navbar-toggler hamburger hamburger--elastic toggle-sidebar"
        v-bind:class="{ 'is-active': sidebarCollapsed }"
        type="button"
        v-on:click="toggleSidebar"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
      <button
        class="navbar-toggler hamburger hamburger--elastic toggle-sidebar-mobile"
        v-bind:class="{ 'is-active': sidebarCollapsedMobile }"
        type="button"
        v-on:click="toggleSidebarMobile"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>

      <ul
        style="
          font-size: 14px;
          padding-left: 0px !important;

          color: #83838c;
        "
        class="d-flex my-auto ml-2 justify-content-between align-items-center"
      >
        <!-- <ol class="d-flex align-items-center" style="padding-left: 0px !important">
                <font-awesome-icon icon="map-marker" class="mr-1" />
                <span class="text-black-50 mr-1 opensans_font"
                  >192.201.183.212</span
                >
              </ol> -->
        <ol
          style="padding-left: 0px !important"
          class="d-flex align-items-center"
        >
          <font-awesome-icon icon="cog" class="mr-1" />
          <span class="text-black-50 mr-1 opensans_font">
            415.0-38-generic</span
          >
        </ol>
        <ol
          style="padding-left: 0px !important"
          class="d-flex align-items-center ml-2"
        >
          <img
            class="mr-1"
            width="18"
            src="@/assets/img/server-summary/cloudstick.svg"
            alt
          />
          <span class="text-black-50 mr-1 opensans_font">2.1.11</span>
        </ol>
        <ol
          style="padding-left: 0px !important"
          class="d-flex align-items-center ml-2"
        >
          <font-awesome-icon icon="tv" class="mr-1" />
          <span class="text-black-50 mr-3 opensans_font">Ubuntu 18.04 LTS</span>
        </ol>
      </ul>
    </div>
    <div class="d-flex align-items-center">
      <!-- <router-link
        to="/"
        style="color: #1778be; box-shadow: none;font-size:14px;"
        class="btn opensans_font"
      >
        12345-3481345</router-link
      > -->
      <!-- <b-button  variant="second">Example I</b-button> -->

      <router-link
        to="/"
        v-b-tooltip.hover
        title="DNS"
        class="d-flex dns_header mx-2"
      >
        <img
          class="dns_header_gradient"
          style="display: none"
          src="@/assets/img/header/dns_header_gradient.svg"
          alt
        />
        <img
          class="dns_header_grey"
          src="@/assets/img/header/dns_header_grey.svg"
          alt
        />
      </router-link>
      <router-link
        to="/backup"
        v-b-tooltip.hover
        title="Backup"
        class="d-flex dns_header mx-2"
      >
        <img
          class="dns_header_gradient"
          style="display: none"
          src="@/assets/img/header/backup_header_gradient.svg"
          alt
        />
        <img
          class="dns_header_grey"
          src="@/assets/img/header/backup_header_grey.svg"
          alt
        />
      </router-link>
      <div
        id="popoverIntegrExample1"
        title="Team"
        class="d-flex dns_header mx-2"
      >
        <img
          class="dns_header_gradient"
          style="display: none"
          src="@/assets/img/header/noti1_header_gradient.svg"
          alt
        />
        <img
          class="dns_header_grey"
          src="@/assets/img/header/noti1_header_grey.svg"
          alt
        />
      </div>
      <b-popover
        style="height: 200px !important"
        id="qw"
        :show.sync="show"
        size="lg"
        placement="auto"
        @show="showPopoverIntegrations"
        ref="popoverIntegrations1"
        target="popoverIntegrExample1"
      >
        <div class="d-flex justify-content-end">
          <img
            @click="show = !show"
            style="cursor: pointer"
            class=""
            src="@/assets/img/bluecross.svg"
            alt
          />
          <!-- <b-button variant="outline-primary mr-2" 
          >Cancel</b-button
        > -->
        </div>

        <VuePerfectScrollbar>
          <div class="" style="border: none; box-shadow: none">
            <div class="d-flex justify-content-center">
              <img
                width="50"
                class="dns_header_grey"
                src="@/assets/img/header/noti1_header_gradient.svg"
                alt
              />
            </div>
            <div class="d-flex justify-content-center">
              <p
                class="mt-3 poppins_font"
                style="font-weight: 600; font-size: 24px"
              >
                Welcome to Change Logs
              </p>
            </div>
            <div>
              <div class="divider mb-3"></div>
              <div class="d-flex justify-content-between opensans_font">
                <p class="my-auto" style="font-weight: 600; color: #000000">
                  Cloning is in progress
                </p>
                <b-button
                  class="px-3"
                  size="sm"
                  style="
                    box-shadow: none;
                    background-color: #e7c92a;
                    border-radius: 30px;
                    min-width: 70px;
                    border: none;
                    padding-y: 6px;
                    font-weight: 600;
                    color: #ffff;
                  "
                  variant="default"
                  >cloning</b-button
                >
              </div>
              <div class="d-flex justify-content-between opensans_font mt-2">
                <span class="mb-0"
                  >File <b>aswinmohanan.com</b> is being cloned.</span
                >
                <p class="mb-0">54 %</p>
              </div>
              <div class="d-flex mt-1">
                <div
                  class="progress-bar-sm progress-bar-animated-alt progress serverinstall w-100 mt-2 mb-4"
                  style="height: 10px !important"
                >
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="width: 55%"
                  ></div>
                </div>
              </div>
            </div>
            <div>
              <div class="divider mb-3"></div>
              <div class="d-flex justify-content-between opensans_font">
                <p class="my-auto" style="font-weight: 600; color: #000000">
                  Cloning has been done.
                </p>
                <b-button
                  class="px-3"
                  size="sm"
                  style="
                    box-shadow: none;
                    border-radius: 30px;
                    min-width: 70px;
                    border: none;
                    padding-y: 6px;
                    font-weight: 600;
                    color: #ffff;
                  "
                  variant="success"
                  >Success</b-button
                >
              </div>
              <div class="d-flex justify-content-between opensans_font mt-2">
                <span class="mb-0"
                  >File <b>aswinmohanan.com</b> was cloned succeessfully.</span
                >
                <p class="mb-0">54 %</p>
              </div>
              <div class="d-flex mt-1">
                <div
                  class="progress-bar-sm progress-bar-animated-alt progress serverinstall w-100 mt-2 mb-4"
                  style="height: 10px !important"
                >
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="width: 55%"
                  ></div>
                </div>
              </div>
            </div>
            <div class="divider mb-3"></div>
            <div class="d-flex justify-content-between opensans_font">
              <p class="my-auto" style="font-weight: 600">
                Change Log 0.1.18 February 2022: Fixes and Improvements
              </p>
              <b-button
                class="px-3"
                size="sm"
                style="
                  box-shadow: none;
                  border-radius: 30px;
                  min-width: 70px;
                  padding-y: 7px;
                  background-color: #1d6ba4;
                  color: white;
                "
                variant="default"
                >Success</b-button
              >
            </div>
            <ul class="opensans_font">
              <li>Released CloudStick Agent 0.1.18</li>
              <li>Fixed: On-demand backup bug</li>
              <li>
                Fixed: Restriction on a team free user to use file manager.
              </li>
              <li>Fixed: Filemanager compress and extract bug</li>
            </ul>
            <div class="divider"></div>
            <div
              v-if="morefeatures == true"
              class="d-flex justify-content-between opensans_font"
            >
              <p class="my-auto">
                Change Log 0.1.18 February 2022: Fixes and Improvements
              </p>
              <b-button
                class="px-3"
                size="sm"
                style="
                  box-shadow: none;
                  border-radius: 30px;
                  min-width: 70px;
                  padding-y: 7px;
                  background-color: #1d6ba4;
                  color: white;
                "
                variant="default"
                >Success</b-button
              >
            </div>
            <ul v-if="morefeatures == true">
              <li>Released CloudStick Agent 0.1.18</li>
              <li>Fixed: On-demand backup bug</li>

              <li>
                Fixed: Restriction on a team free user to use file manager.
              </li>

              <li>Fixed: Filemanager compress and extract bug</li>
            </ul>
            <!-- <b-button  to="" v-if="morefeatures == false"  variant="outline-warning"  @click.native="MoreFeatures()">more</b-button> -->
            <router-link
              v-if="morefeatures == false"
              to=""
              title="Learn more"
              class="px-4 py-2 font-weight-bold subscription-features"
              @click.native="MoreFeatures()"
            >
              <span>View More</span>
            </router-link>
            <router-link
              v-if="morefeatures == true"
              tag="a"
              to=""
              title="Learn less"
              class="px-4 py-2 font-weight-bold subscription-features"
              @click.native="MoreFeatures()"
            >
              <span> View Less</span>
            </router-link>
          </div>
        </VuePerfectScrollbar>
        <!-- <ul class="list-group list-group-flush text-left bg-transparent">
                <li class="list-group-item rounded-top">
                    <div class="align-box-row">
                        <div>
                            <div class="avatar-icon-wrapper avatar-icon-md">
                                <div class="avatar-icon rounded-circle"><img
                                        src="@/assets/img/avatars/avatar2.jpg"
                                        alt=""></div>
                            </div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe <small class="text-black-50">(john@example.com)</small></span>
                            <b-badge variant="success">Active Account</b-badge>
                        </div>
                    </div>
                    <div class="alert alert-warning p-2 mb-1 mt-3 rounded">
                        <div class="align-box-row">
                            <div>
                                <font-awesome-icon icon="bell"/>
                            </div>
                            <div class="pl-3">
                                <small><b class="d-block">Unpaid invoice</b></small>
                                <small>This account will be disabled starting <b>12 January 2021</b>.</small>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="list-group-item bg-transparent p-0">
                    <div class="grid-menu grid-menu-2col">
                        <div class="no-gutters row">
                            <div class="col-sm-6 py-3">
                                <div class="text-center">
                                    <div>
                                        <font-awesome-icon icon="chart-bar" class="font-size-xxl text-info"/>
                                    </div>
                                    <div class="mt-2 line-height-sm">
                                        <b class="font-size-lg">$9,693</b>
                                        <span class="text-black-50 d-block">revenue</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 py-3">
                                <div class="text-center">
                                    <div>
                                        <font-awesome-icon icon="user" class="font-size-xxl text-success"/>
                                    </div>
                                    <div class="mt-2 line-height-sm">
                                        <b class="font-size-lg">2,345</b>
                                        <span class="text-black-50 d-block">users</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
           
            </ul> -->
      </b-popover>
      <router-link
        to="/team"
        v-b-tooltip.hover
        title="Team"
        class="d-flex dns_header mx-2"
      >
        <img
          class="dns_header_gradient"
          style="display: none"
          src="@/assets/img/header/team_header_gradient.svg"
          alt
        />
        <img
          class="dns_header_grey"
          src="@/assets/img/header/team_header_grey.svg"
          alt
        />
      </router-link>

      <router-link
        to="/team"
        v-b-tooltip.hover
        title="Team"
        class="d-flex dns_header mx-2"
      >
        <img
          class="dns_header_gradient"
          style="display: none"
          src="@/assets/img/header/noti2_header_gradient.svg"
          alt
        />
        <img
          class="dns_header_grey"
          src="@/assets/img/header/noti2_header_grey.svg"
          alt
        />
      </router-link>
      <!-- <router-link
          to="/backup"
          style="box-shadow: none"
          class="btn pl-2 opensans_font pr-2"
        >
          Backup</router-link
        >
        <router-link
          to="/dns"
          style="box-shadow: none"
          class="btn pl-2 pr-2 opensans_font"
        >
          DNS
        </router-link>
        <router-link to="/team" class="btn opensans_font pl-2 pr-2">
          Team
        </router-link>

        <img class="mx-3" src="@/assets/img/header/headericon1orange.svg" alt />

        <img class="mx-3" src="@/assets/img/header/headericon2orange.svg" alt /> -->

      <b-form-select
        style="width: auto"
        size="sm"
        v-model="selected"
        :options="options"
      ></b-form-select>
      <!-- <UserBox /> -->
      <b-dropdown
        variant="default"
        class="dropdown-menu-xl p-0 ml-2"
        right
        no-caret
      >
        <template slot="button-content" >
          <div class="avatar-icon-wrapper avatar-icon-md">
            <span class="badge badge-circle badge-success"></span>
            <div class="avatar-icon">
              <img src="@/assets/img/avatars/avatar4.jpg" alt="" />
            </div>
          </div>
        </template>
        <div class="bg-composed-wrapper bg-asteroid">
          <div class="bg-composed-wrapper--image bg-composed-img-2"></div>
        </div>

        <div class="divider"></div>
        <div class="card-body d-block text-center">
          <div class="avatar-icon-wrapper avatar-icon-lg">
            <span class="badge badge-circle badge-success"></span>
            <div class="avatar-icon">
              <img src="@/assets/img/avatars/avatar4.jpg" alt="" />
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <p class="mb-0" style="font-size: 14px; font-weight: 600">
              Aswin Mohanan
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <p
              class="mb-1"
              style="font-size: 13px; font-weight: 400; color: #83838c"
            >
              aswinmohnan@abc.com
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <p style="font-size: 13px; font-weight: 400; color: #1778be">
              1234556/6565
            </p>
          </div>
          <div class="divider"></div>
          <div class="d-flex mt-3 justify-content-center">
            <p
              class="mb-2"
              style="font-size: 13px; font-weight: 400; color: #83838c"
            >
              Account Status : Basic
            </p>
          </div>
          <b-button
            class="pl-5 pr-5"
            size="sm"
            style="box-shadow: none"
            variant="primary"
            >Upgrade to Pro</b-button
          >
          <div class="divider mt-3"></div>
          <div class="d-flex mt-3 justify-content-center">
            <p
              class="mb-2"
              style="font-size: 13px; font-weight: 400; color: #171a42"
            >
              My Profile
            </p>
          </div>
          <b-button
            class="pl-4 pr-4"
            size="sm"
            style="box-shadow: none; border: 0.5px solid #d2d2d2"
            variant="default"
            >Logout</b-button
          >
        </div>
      </b-dropdown>
    </div>
    <b-modal id="search-modal" centered>
      <div slot="modal-header">
        <h6 class="display-4 font-weight-bold">Search</h6>
        <p class="text-black-50 mb-0">Use the form below to search for files</p>
      </div>
      <div class="p-5">
        <div class="input-group">
          <input
            type="search"
            class="form-control"
            placeholder="Search terms here..."
          />
          <div class="input-group-append">
            <b-button variant="primary" class="border-0">
              <font-awesome-icon icon="search" />
            </b-button>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="d-block w-100">
        <b-button
          variant="link"
          block
          class="btn-link-dark"
          @click="$bvModal.hide('search-modal')"
          >Close search box</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
// import UserBox from "./Header/UserBox";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faCloudUploadAlt,
  faTasks,
  faEnvelope,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faSearch, faCloudUploadAlt, faTasks, faEnvelope, faUsersCog);
export default {
  name: "Header",
  components: {
    // UserBox,
    "font-awesome-icon": FontAwesomeIcon,
  },

  data() {
    return {
      show: false,
      morefeatures: false,

      selected: null,
      options: [
        { value: null, text: "English" },
        { value: "a", text: "German" },
        { value: "b", text: "Spanish" },
        { value: "c", text: "Italian" },
      ],
    };
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      },
    },
    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      },
    },
  },
  methods: {
    MoreFeatures() {
      this.morefeatures = !this.morefeatures;
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    toggleSidebarMobile() {
      this.sidebarCollapsedMobile = !this.sidebarCollapsedMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  background: linear-gradient(
    165.14deg,
    #1b67a4 -0.17%,
    #44d695 99.52%
  ) !important;
}
.popover {
  min-width: 40em !important;
}
.dns_header:hover .dns_header_grey,
.dns_header:hover ~ .dns_header_grey {
  display: none !important;
}
.dns_header:hover .dns_header_gradient,
.dns_header:hover ~ .dns_header_gradient {
  display: block !important;
}
@media only screen and (max-width: 991px) {
  .menu-item {
    display: none !important;
  }
  .mobile-view {
    display: block !important;
  }
}
</style>
